<template>
  <div class="policy_warpper">
    <div class="tit1">
      <div class="city">
        上游产业城市：<span>{{ obj.upstreamNum }}</span> 个
      </div>
      <div class="city">
        下游产业城市：<span>{{ obj.downstreamNum }}</span> 个
      </div>
    </div>
    <div
      class="cylBox1"
      style="display: flex; justify-content: space-around; align-items: center"
    >
      <div class="boxBg">
        <dv-scroll-board
          :config="config"
          style="width: 220px; height: 200px"
          carousel="single"
        />
      </div>
      <div class="boxBg">
        <dv-scroll-board
          :config="config2"
          style="width: 220px; height: 200px"
          carousel="single"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import {getCompanyList,getPolicyList} from "../request/moudle/index";
export default {
  props: ["activeId"],
  data() {
    return {
      config: {},
      header1: ["省份", "城市"],
      config2: {},
      header2: ["省份", "城市"],
      // data1: [
      //     ['行1列1', '行1列2', '行1列3','行1列3'],
      //     ['行2列1', '行2列2', '行2列3','行1列3'],
      //     ['行3列1', '行3列2', '行3列3','行1列3'],
      //     ['行4列1', '行4列2', '行4列3','行1列3'],
      //     ['行5列1', '行5列2', '行5列3','行1列3'],
      //     ['行6列1', '行6列2', '行6列3','行1列3'],
      //     ['行7列1', '行7列2', '行7列3','行1列3'],
      //     ['行8列1', '行8列2', '行8列3','行1列3'],
      //     ['行9列1', '行9列2', '行9列3','行1列3'],
      //     ['行10列1', '行10列2', '行10列3','行1列3']
      // ],
      data1: [],
      data2: [],
      obj: {
        // 下游产业城市个数
        downstreamNum: "",
        // 上游产业城市个数
        upstreamNum: "",
        downstreamRegion: [],
        upstreamRegion: [],
      },
    };
  },
  mounted() {
    // 定时刷新时间
    // this.timer = setInterval(()=> {
    // this.getCompanyData() // 修改数据date
    // }, 4000)
  },
  destroyed() {
    if (this.timer) {
      // 注意在vue实例销毁前，清除我们的定时器
      clearInterval(this.timer);
    }
  },
  watch: {
    activeId: {
      //  console.log("=>>>>>",now);
      handler(val, old) {
        // console.log("=>>>>>",val);
        this.getCompanyData();
      },
      immediate: true,
    },
  },
  methods: {
    getCompanyData() {
      let _this = this;
      //  console.log("getCompanyData",_this.activeId);
      _this.data1 = [];
      _this.data2 = [];
      _this.obj.upstreamRegion = [];
      _this.obj.downstreamRegion = [];
      _this.$api.index.getIndustrial(_this.activeId).then((response) => {
        //  console.log(response)
        _this.obj.upstreamRegion = response.upstreamRegion;
        _this.obj.downstreamRegion = response.downstreamRegion;
        _this.obj.upstreamNum = response.upstreamNum;
        _this.obj.downstreamNum = response.downstreamNum;
        if (_this.obj.upstreamRegion) {
          var n = [];
          _this.obj.upstreamRegion.map((v, k) => {
            n.push(v.provinceName);
            n.push(v.name);
            _this.data1.push(n);
            n = [];
          });
          _this.config = {
            data: _this.data1, // 表数据
            header: _this.header1, // 表头数据
            rowNum: 4, // 表行数
            headerBGC: "none", // 表头背景色
            oddRowBGC: "none", // 奇数行背景色
            evenRowBGC: "none", // 偶数行背景色
            waitTime: 2000, // 轮播时间间隔(ms)
            align: "center",
          };
        }
        if (_this.obj.downstreamRegion) {
          // console.log(res2)
          var n2 = [];
          _this.obj.downstreamRegion.map((v, k) => {
            n2.push(v.provinceName);
            n2.push(v.name);
            _this.data2.push(n2);
            n2 = [];
          });
          _this.config2 = {
            data: _this.data2, // 表数据
            header: _this.header2, // 表头数据
            rowNum: 4, // 表行数
            headerBGC: "none", // 表头背景色
            oddRowBGC: "none", // 奇数行背景色
            evenRowBGC: "none", // 偶数行背景色
            waitTime: 2000, // 轮播时间间隔(ms)
            align: "center",
          };
        }
      });
    },
  },
};
</script>
<style lang="scss" >
.policy_warpper {
  .tit1 {
    margin: 27px 0px 17px 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    letter-spacing: 2px;
    font-weight: 500;
    .city {
      color: #f8f8f8;
      font-size: 20px;
      span {
        color: #79f7f8 !important;
        font-weight: 500;
        font-size: 23px !important;
      }
    }
  }
  .cylBox1 {
    .dv-scroll-board {
      .header {
        text-align: center;
        white-space: nowrap;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 600;
        color: #095cf8 !important;
      }
      .rows {
        padding: 0px 6px;
        .row-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: nowrap !important;
          text-align: center;
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 300;
          color: #fefeff;
          .ceil {
            width: 80px !important;
            // font-size: 1em;
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #fefeff;
            i {
              font-size: 0.5em;
              color: #3bc080;
            }
          }
        }
      }
    }
  }
}
</style>
