
//    /blade-shubh/
import axios from '../http';
var api = "/api"
// 产业集群上下游坐标
export const clusterRegionCoord = (params) => {
    return axios({
        url: api + '/shubh_map/clusterRegionCoord',
        method: 'get',
        params
    })
}
// 产业分类
export const shubhClusterType = (params) => {
    return axios({
        url: api + '/shubh_map/shubhClusterType',
        method: "get",
        params
    })
}

// 获取政策列表
export const getPolicyList = (current, size, params) => {
    return axios({
      url: api +'/shubhpolicy/list',
      method: 'get',
      params: {
        ...params,
        current,
        size,
      }
    })
  }

// 获取企业列表
export const getCompanyList = (current, size, params) => {
    return axios({
      url: api +'/shubhfirm/list',
      method: 'get',
      params: {
        ...params,
        current,
        size,
      }
    })
  }

// 获取企业上下游
export const getIndustrial = (id) => {
  return axios({
    url: api +'/shubh_map/industrialChain/'+id,
    method: 'get',
    data: id
  })
}



