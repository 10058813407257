<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    provide() {
        return {
            reload: this.reload,
        };
    },
    data() {
        return {
            
        };
    },
    computed: {
        
    },
    mounted() {
        
    },
    methods: {
        
    },
};
</script>


<style lang="scss" scoped>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-align: center; */
    height: 100%;
    i {
        font-style: normal;
    }
    
}
.filter {
    filter: blur(6px);
}
html,body{
    width: 100%;
    height: 100%;
}

</style>
