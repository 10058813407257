<template>
  <div>
       <div class="tit"><img :src="imgQy" width="225px" height="21px"/></div>
       <div class="cylBox">
           <div class="boxBg">
              <dv-scroll-board
               :config="config"
              style="width:769px;height:200px" carousel="single" />
           </div>
       </div>
  </div>
</template>

<script>
// import {getCompanyList,getPolicyList} from "../request/moudle/index";
export default{

  data(){
      return{
          imgQy:require("../assets/icons/qy.png"),
          page: {
          pageSize: 176,
          currentPage: 1,
          total: 0
          },
          config:{},
          header1: ['企业名称', '所属产业', '企业名称','所属产业'],
          // data1: [
          //     ['行1列1', '行1列2', '行1列3','行1列3'],
          //     ['行2列1', '行2列2', '行2列3','行1列3'],
          //     ['行3列1', '行3列2', '行3列3','行1列3'],
          //     ['行4列1', '行4列2', '行4列3','行1列3'],
          //     ['行5列1', '行5列2', '行5列3','行1列3'],
          //     ['行6列1', '行6列2', '行6列3','行1列3'],
          //     ['行7列1', '行7列2', '行7列3','行1列3'],
          //     ['行8列1', '行8列2', '行8列3','行1列3'],
          //     ['行9列1', '行9列2', '行9列3','行1列3'],
          //     ['行10列1', '行10列2', '行10列3','行1列3']
          // ],
          data2: [],
      }  
  },
  mounted(){
      this.getCompanyData();
  },
  methods: {
      getCompanyData(){
          let _this = this;
           _this.$api.index.getCompanyList(this.page.currentPage, this.page.pageSize).then((response) => {
            //  console.log(response)
             if(response.code==200){
               if(response.data.records){
                   var res= response.data.records;
                    // console.log(res)
                      var n = [];
                      res.map((v,k) => {
                        if(k%4 == 3){
                          n.push(v.firmClass)
                          this.data2.push(n)
                          n = []
                        }else{
                          if(k != res.length - 1){
                            if(k%2 == 0){
                              n.push(v.firmName)
                            }else{
                              n.push(v.firmClass)
                            }
                          }else{
                            if(k%2 == 0){
                              n.push(v.firmName)
                            }else{
                              n.push(v.firmClass)
                            }
                            this.data2.push(n)
                          }
                        }
                      })
                      this.config = {
                        data: this.data2, // 表数据
                        header: this.header1, // 表头数据
                        rowNum:4, // 表行数
                        headerBGC: 'none', // 表头背景色
                        oddRowBGC: 'none', // 奇数行背景色
                        evenRowBGC: 'none', // 偶数行背景色
                        waitTime: 2000, // 轮播时间间隔(ms)
                        align: 'center'
                    }
               }
               
             }
             
        });

      }
  
  }
}

</script>
<style lang="scss" >
.tit{
  margin:27px 0px 17px 48px;
}
.cylBox{
          .dv-scroll-board{
            .header{
              text-align: center;
              white-space: nowrap;
              font-size: 20px;
              font-family: Source Han Sans CN;
              font-weight: 600;
              color: #095CF8;
            }
            .rows{
              padding:0px 30px;
              .row-item{
                display:flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                text-align: center;
                font-size: 15px;
                font-family: Source Han Sans CN;
                font-weight: 300;
                color: #FEFEFF;
                .ceil{
                  width: 170px !important;
                  // font-size: 1em;
                  font-size: 15px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FEFEFF;
                  i{
                    font-size: 0.5em;
                    color: #3bc080;
                  }
                }
              }
            }
          }
}       
</style>
