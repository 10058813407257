import axios from "axios";
import Cookies from 'js-cookie';
var baseUrl

// if (process.env.NODE_ENV == 'development') {
//     baseUrl = '/'
// } else if (process.env.NODE_ENV == 'production') {
//     baseUrl = 'http://27.128.115.201:9112/'
// } else if (process.env.NODE_ENV == 'testing') {
//     baseUrl = '/'
// }

const instance = axios.create({
    // baseURL: baseUrl,
    timeout: 100000,
    withCredentials: true,
})

// 请求拦截
// 所有网络请求会先走这里
instance.interceptors.request.use(config => {
    let token = Cookies.get('token')
    if (token) {
        config.headers['Blade-Auth'] = token
    }
    if(config.headers.tenantId){
        config.headers['Tenant-Id'] = config.headers.tenantId
    }else{
        config.headers['Tenant-Id'] = "000000"
    }
    config.headers['Authorization'] = "Basic c2FiZXI6c2FiZXJfc2VjcmV0"
    return config;
}, err => {
    return Promise.reject(err)
});

// 响应拦截
// 此处可根据服务器的返回的状态码做响应处理
instance.interceptors.response.use(response => {
    return response.data
}, err => {
    let { response } = err;
    if (response) {
        switch (response.status) {
            case 400:
                err.message = response.data.error_description
                break
            case 401:

                break
            case 403:
                err.message = response.data.msg
                break
            case 403:
                err.message = '未授权，请登录'
                break
            case 404:
                err.message = `请求地址出错: ${err.response.config.url}`
                break
            case 500:
                err.message = '服务器内部错误'
                break
        }
    } else {
        if (!window.navigator.onLine) {
            // console.log('断网了')
        }
    }
    return Promise.reject(err)
})

export default instance;